.logo {
   font-size: 55px;
   color: black;
   transition: 0.75s;
}

.logo:hover {
    color: lightsalmon;
    font-size: 65px;
    transition: 0.50s;
}

/* header {
    background-color: var(--sand-color);
    width: 100vw;
    position:fixed;
    top: 0;
    height: 60px;
} */


nav {
    background-color: var(--sand-color);
    width: 100vw;
    position:fixed;
    top: 0;
    height: 60px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    gap: 8vw;
}

.logo-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
}

.link {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    transition: 0.25s;

}

.link:hover {
    /* background-color: lightsalmon; */
    /* color: white;  */
    font-size: 27px;
    transition: 0.25s;
    color: lightsalmon;
}

.link .selected {
    background-color: lightsalmon;
    color: white;
}

/* TABLET 85% of desktop size */
@media only screen and (max-width: 935px) {
    .logo {
        font-size: 52.17px;
    }
    .logo:hover {
        font-size: 62px;
    }
    .link {
        font-size: 1.30rem;
    }
    .link:hover {
        font-size: 1.60rem;
    }
}

/* PHONE 75% of desktop size */
/* @media only screen and (max-width: 615px) {
    .logo {
        font-size: 48px;
    }
    .link {
        font-size: 1.1rem;
    }
    header {
        position: relative;
    }
} */

/* PHONE 60% of desktop size */
@media only screen and (max-width: 615px) {
    .logo {
        font-size: 35.75px;
    }
    .logo:hover {
        font-size: 43px
    }
    .link {
        font-size: 0.975rem;
    }
    .link:hover {
        font-size: 1.15rem;
    }
}