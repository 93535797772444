.about-ctr-main {
    background-color: var(--buffed-beige-color);
    min-height: 110vh;
    /* padding-top: 6rem; */
}
.about-ctr-main > h3 {
    color: black;
}

.about-ctr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.headshot > img {
    width: 28vw;
    height: auto;
    border-radius: var(--radius);
}

.about-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 90vw;
    gap: 1rem;
    /* border: 1px solid red; */
}

.headshot {
    grid-column: 1 / span 1;
    grid-row: 1/ span 2;
}

.skills {
    /* border: 1.5px solid black; */
    border-radius: var(--radius);
    background-color: var(--sand-color);
    padding: 10px;
    /* gap: 0.6rem; */
    justify-content: space-evenly;

}

.education {
    /* border: 1.5px solid black; */
    border-radius: var(--radius);
    /* gap: 0.60rem; */
    background-color: var(--sand-color);
    padding: 10px;
    justify-content: space-evenly;


}

.about-ctr-main .react-icon {
    font-size: 35px;
}

.education h4 {
    font-size: 1.25rem;
}

.education p {
    font-size: 1.15rem;
}

.school {
    text-align: center;
}

.skills-item-ctr {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-top: 10px;
}

.bio {
    grid-column: 2 / span 2;
    font-size: 1.15rem;
    display: flex;
    align-items: center;
    /* border: 1.5px solid black; */
    border-radius: var(--radius);
    padding: 10px;
    background-color: var(--sand-color);
}

/* tablet view */
@media only screen and (max-width: 935px) {
    .about-ctr-main {
        height: auto;
    }
    .about-ctr-main .intro {
        margin-top: 50px;
    }
    .about-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        margin-bottom: 50px;
    }
    .headshot {
        grid-column: 1 / span 2;
        grid-row: 1/ span 1;
    }
    .headshot img {
        width: 35vw;
        height: auto;
    }
    .education {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
    }
    .skill {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
    }
    .bio {
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
    }
}

/* mobile view */
@media only screen and (max-width: 615px) {
    .about-ctr-main {
        height: auto;
    }
    .about-ctr-main .intro {
        margin-top: 50px;
    }
    .about-grid {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 20px;
        margin-bottom: 50px;
        padding: 0;

    }
    .headshot {
        grid-column: 1 / span 1;
        grid-row: 1/ span 1;
        /* max-height: 200px; */

    }
    .headshot img {
        width: 50vw;
        height: auto;

    }
    .education {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        max-height: 200px;

    }
    .skills {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        max-height: 200px;

    }
    .bio {
        grid-column: 1 / span 1;
        grid-row: 4 / span 1;
        
    } 
}