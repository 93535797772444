@import 'typed';

.home-ctr {
    min-height: 100vh;
    // display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
}

.home-icon {
    color: lightsalmon;
    font-size: 100px;
    padding-left: 20px;
}

.home-ctr h1 {
    font-size: 87.5px;
}
.home-ctr h2 {
    font-size: 50px;
}
.home-ctr h3 {
    font-size: 31.25px;
}
// .home-ctr h4 {
//     font-size: 25px;
//     font-weight: bolder;
// }

.intro-container {
    padding-top: 30vh;
    display: flex;
    align-items: flex-end;
}

.intro-container h3 {
    display: inline;
}

.intro {
    color: salmon;
}

span.typed-text {
    color: white;
}

.typed-text {
	padding: 0 0 0 3px;
	background-color: lightsalmon;
	&::before { font-weight: 600; }
	@include typed(
		"Software Engineer!", "Full-Stack Developer!", "Registered Nurse!", 
		2,
		(caret-width: 2px, caret-space: 2px)
	);
}

.home-ctr button {
    margin: 5px;
    height: 2.5rem;
    border-radius: var(--button-radius);
    background-color: var(--sand-color);
    border: 1px solid black;
    font-size: 1rem;
    margin-top: 0.75rem;
    align-items: center;
    color: black;
}

.home-ctr button:hover {
    background-color: lightsalmon;
    color: white;
    cursor: pointer;
}

// TABLET - fonts 
@media only screen and (max-width: 935px) {
    .home-ctr {
        height: 100vh;
    }
}

// MOBILE - fonts 60% of desktop size
@media only screen and (max-width: 615px) {
    .home-ctr {
        height: 100vh;
    }
    .home-ctr h1 {
        font-size: 62.5px;
    }
    .home-ctr h2 {
        font-size: 35.7px;
    }
    .home-ctr h3 {
        font-size: 22.32px;
    }
    .home-icon {
        font-size: 71.4px;
    }
    .intro-container {
        padding-top: 18vh;
    }
}

@keyframes rainbow {
    0% {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px red, 0 0 20px red, 0 0 25px red, 0 0 30px red, 0 0 35px red;
    }
    20% {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px orange, 0 0 20px orange, 0 0 25px orange, 0 0 30px orangepurple, 0 0 35px orange;
    }
    40% {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px yellow, 0 0 20px yellow, 0 0 25px yellow, 0 0 30px yellow, 0 0 35px yellow;
    }
    60% {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(133, 234, 133), 0 0 20px rgb(133, 234, 133), 0 0 25px rgb(133, 234, 133), 0 0 30px rgb(133, 234, 133), 0 0 35px rgb(133, 234, 133);
    }
    80% {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(90, 162, 239), 0 0 20px rgb(90, 162, 239), 0 0 25px rgb(90, 162, 239), 0 0 30px rgb(90, 162, 239), 0 0 35px rgb(90, 162, 239);   
    }
    100% {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px violet, 0 0 20px violet, 0 0 25px violet, 0 0 30px violet, 0 0 35px violet;   
    }
}

@keyframes flicker{
    40% {
        opacity: 1;
    }
    42% {
        opacity: 0.8;
    }
    43% {
        opacity: 1;
    }
    45% {
        opacity: 0.3;
    }
    46% {
        opacity: 1;
    }
    }