.contact-ctr-main {
    /* border: 1px solid red; */
    min-height: 100vh;
    background-color:var(--latte-color);
}

.contact-ctr-main > h3 {
    margin-top: 4rem;
}

.contact-ctr-main h3, .contact-ctr-main h1 {
    color: var(--taupe-color);
}

.contact-ctr {
    margin-top: 5rem;
}

.contact-form {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0.88rem;
}


.contact-form label{
    color: var(--taupe-color);
    font-weight: 800;
    display: flex;
    justify-content: flex-end;
}

.contact-form input {
    padding: 5px;
    width: 25vw;
    background-color: var(--taupe-color);
    border: none;
}

.contact-form textarea {
    height: 10vh;
    background-color: var(--taupe-color);
    border: none;
}

.contact-form input[type=submit] {
    border-radius: var(--button-radius);
    border: 1px solid var(--sand-color);
    color: rgb(127, 121, 113);
    /* color: black; */
}

.contact-form input[type=submit]:hover {
    background-color: var(--pearl-color);
}

@media only screen and (max-width: 935px) {
    .contact-form input, .contact-form textarea {
        width: 50vw;
    }
}

@media only screen and (max-width: 615px) {

}


/* .contact-ctr {
    margin-top: 5rem;
} */