footer {
    background-color: rgba(232, 227, 215, 1.0);
    margin-top: auto;
    color: black;
    bottom: 0;
    width: 100%;
    height: 5vh;
    gap: 15px;
}

.social-media-ctr {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

footer > h4 {
    font-size: 1.5rem;
}

.social-media-icon {
    color: black;
    font-size: 22px;
    transition: 0.5s;
}

.social-media-icon:hover {
    /* background-color: lightsalmon; */
    /* border-radius: 5px; */
    color: lightsalmon;
    /* font-size: 28px; */
    transition: 0.25s
}

.footer-text {
    font-size: 14px;
}

.blog-link {
    color: black;
    text-decoration: none;
    transition: 0.5s
}

.blog-link:hover {
    color: lightsalmon;
    transition: 0.25s
}