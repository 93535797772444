.projects-ctr-main {
    height: auto;
    min-height: 110vh;
    /* padding-top: 2rem;; */
    background-color: var(--terracotta-color);
    /* border: 1px solid red; */
}

.projects-ctr-main > h3 {
    margin-top: 5rem;
}

.projects-grid-ctr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 5rem;
    width: 90vw;
    margin-bottom: 5rem;
    /* border: 2px solid red; */
}

.project-card {
    border-radius: var(--radius);
    background-color: var(--sand-color);
    /* border: 1.5px solid var(--olive-color); */
}

/* .project-card:hover {
    box-shadow: var(--shadow);
} */

img.project-img {
    width: 20vw;
    height: 20vw;
    margin-top: 30px;
    border-radius: var(--radius);
    /* border: 2px solid red; */
}

.project-card h2 {
    padding-top: 25px;
    padding-bottom: 10px;
}

.project-tools {
    display: none;
    width: 20vw;
    height: 20vw;
    margin-top: 30px;
    border-radius: var(--radius);
}

.project-img-div:hover .project-tools {
    /* display: block; */
    /* background-color: var(--sweet-beige-color); */
    background-color: var(--pearl-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* transition: 1s; */
    overflow: auto;
}

.project-img-div:hover .project-tools h3 {
    background-color: var(--latte-color);
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    color: var(--taupe-color);
    padding: 0.25rem
}

.project-img-div:hover .project-tools h4 {
    /* background-color: var(--latte-color); */
    /* border-bottom-left-radius: 25px; */
    /* border-bottom-right-radius: 25px; */
    /* color: var(--taupe-color) */
    padding: 0.25rem;
}

.project-img-div:hover .project-img {
    display: none;
    /* transition: 1s; */
}

.projects-ctr-main button {
    /* border: 2px solid red; */
    margin: 10px;
    height: 3rem;
    width: 7rem;
    border-radius: var(--radius);
    font-size: 15px;
    border: 1.5px solid var(--olive-color);
    /* background-color: var(--sand-color); */
    color: black;
    transition: 0.15s;
}

.live-button {
    /* background-color: rgb(224, 192, 159); */
    background-color: rgb(192, 174, 154);
}

.github-button {
    /* background-color: rgb(230, 213, 198); */
    background-color: rgb(222, 207, 188);

}

.project-card button:disabled {
    color: grey;
}

.projects-ctr-main button:hover {
    background-color: var(--pearl-color);
    /* color: white; */
    font-size: 18px;
    transition: 0.15s;
    cursor:pointer
}

/* .projects-ctr-main button:disabled {
    background-color: var(--spring-rain-color);
} */

.projects-ctr-main button:disabled:hover {
    /* background-color: var(--spring-rain-color); */
    margin: 10px;
    height: 3rem;
    width: 7rem;
    border-radius: 10px;
    font-size: 15px;
    border: 1.5px solid var(--olive-color);
    background-color: var(--sand-color);
}

.projects-ctr-main .buttons-div {
    display: flex;
    padding-bottom: 10px;
}

.project-card .description {
    margin: auto auto;
    padding: 10px;
    text-align: center;
}

.project-card .tool {
    background-color: var(--latte-color);
    color: white;
    border-radius: var(--radius);
    padding: 3px;
}

.tools-list-ctr {
    flex-wrap: wrap;
    gap: 5px;
    margin: 25px;
}

/* @media only screen and (max-width: 935px) { */
@media only screen and (max-width: 1050px) {
    .projects-grid-ctr {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
    img.project-img {
        width: 30vw;
        height: 30vw;
    }
    .project-tools {
        width: 30vw;
        height: 30vw;
    }
}

@media only screen and (max-width: 615px) {
    .projects-ctr-main {
        height: auto;
    }

    .projects-ctr-main > h3 {
        margin-top: 50px;
    }

    .projects-grid-ctr {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        margin-bottom: 50px;
    }
    img.project-img {
        width: 70vw;
        height: 70vw;
    }
    .project-tools {
        width: 70vw;
        height: 70vw;
    }
}