.error-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 235, 228, 1.0) ;
    height: 100vh;
    /* margin-top: 60px; */
}

.error-div img {
    width: 500px;
    height: auto;
}