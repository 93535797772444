#scrollToTop {
    position: fixed;
    bottom: 60px;
    right: 60px;
    text-align: center;
    cursor: pointer;
    line-height: 0;
    text-decoration: "none";
    font-size: 2rem;
}

#scroll-up-icon {
    /* color: var(--latte-color); */
    color: var(--spring-rain-color);
    padding: 5px
}

#scroll-up-icon:hover {
    color: lightsalmon;
    /* color: var(--latte-color); */
    background-color: white;
    border-radius: 50%;
}

@media only screen and (max-width: 615px) {
    #scrollToTop {
        bottom: 40px;
        right: 20px;
    }
}