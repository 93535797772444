.App {
  background-color: rgba(248, 223, 219, 1.0);
  /* background-color: rgba(246, 246, 244, 1.0); */
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 1rem;
  line-height: 1.5rem;

    /* source: https://moderncss.dev/generating-font-size-css-rules-and-creating-a-fluid-type-scale/ */
    min-height: 100vh;
    color: black;
    font-family: 'Lato', sans-serif;
}

p,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* overflow-wrap: break-word; */
  /* hyphens: auto; */
  margin:0;
  line-height: calc(.125rem + 2ex + .125rem);
}

/* adding a `modular scale` for headings based on a perfect fifth - source: https://www.modularscale.com/?16&px&1.5*/
h1 {
  font-size: 3.375rem;
}
h2 {
  font-size: 2.25rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.667rem;
}

/* main{
  padding: 4rem 0 6rem 0;
} */

/* section {
  margin: 2rem 0 4rem 0;
} */

.flex-ctr-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex-ctr-ctr-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col-btw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

text-ctr {
  text-align: center;
}

.hidden {
  display: none;
}

.App *:focus {
  outline-color: lightsalmon
}

/* TABLET - fonts 85% of desktop size */
@media only screen and (max-width: 935px) {
  h1 {
    font-size: 2.93rem;
  }
  h2 {
    font-size: 1.95rem;
  }
  h3 {
    font-size: 1.30rem;
  }
}

/* MOBILE - fonts 75% of desktop size */
@media only screen and (max-width: 615px) {
  h1 {
    font-size: 2.7rem;
  }
  h2 {
    font-size: 1.80rem;
  }
  h3 {
    font-size: 1.2rem;
  }
}