* {
  box-sizing: border-box;
}

:root {
    --light-beige-color: rgba(249, 248, 242, 1.0);
    --terracotta-color: rgba(203, 177, 153, 1.0);
    --ash-color: rgba(230, 218, 197, 1.0);
    --oat-color: rgba(229, 224, 220, 1.0);
    --sweet-beige-color: rgba(223, 213, 202, 1.0);
    --taupe-color: rgba(238, 234, 228, 1.0);
    --latte-color: rgba(164, 153, 140, 1.0);
    --sand-color: rgba(232, 227, 215, 1.0);
    --olive-color: rgba(147, 142, 115, 1.0);
    --buffed-beige-color: rgba(209, 191, 177, 1.0);
    --pearl-color: rgba(246, 246, 244, 1.0);
    --spring-rain-color: rgba(218, 222, 224, 1.0);

    --radius: 15px;
    --button-radius: 0.15rem;
    --shadow: 0 0.1rem 0.2rem 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}